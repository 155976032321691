import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
// import {notifyWarn} from './common';
import { usePagination } from './usePagination';
import moment from 'moment';

export const useGetOrderProductsReconciled = () => {

  // useEffect(function() {
  //   notifyWarn('Filter options are required. Please open Filter panel and set params.');
  // }, []);

  const prepareParams = useCallback(({fromDateOrder, toDateOrder, fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet}) => {
    const params = {};
    if (fromDateOrder) { params.order_from = encodeURIComponent(fromDateOrder.startOf('day').toString());}
    if (toDateOrder) { params.order_to = encodeURIComponent(toDateOrder.endOf('day').toString());}
    if (fromDateCharge) { params.charge_from = encodeURIComponent(fromDateCharge.startOf('day').toString());}
    if (toDateCharge) { params.charge_to = encodeURIComponent(toDateCharge.endOf('day').toString());}
    if (type) { params.type = encodeURIComponent(type);}
    if (fromDiffNet) params.diff_net_from = fromDiffNet;
    if (toDiffNet) params.diff_net_to = toDiffNet;

    return params
  }, []);

  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((dataPromise) => {
    // setCount(0);
    setList([]);
    setInProgress(true);

    dataPromise.then(response => {
      setInProgress(false);

      if (response?.data?.count) {
        setCount(response.data.count);

        if (response?.data?.data) {
          setList(response.data.data);
        }
      } else {
        setCount(0);
        setList([]);
      }
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      toastHttpError(response);
    });
  }, [setCount, setList, setInProgress]);

  const { fromDateOrder, toDateOrder, fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet, setFromDateOrder, setToDateOrder } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    !fromDateOrder && setFromDateOrder(moment());
    !toDateOrder && setToDateOrder(moment());
  }, [dataReload, fromDateOrder, toDateOrder, setFromDateOrder, setToDateOrder]);

  useEffect(function() {

    if (fromDateOrder || toDateOrder || fromDateCharge || toDateCharge || type || fromDiffNet || toDiffNet) {

      handleResponse(axios().get('/reports/order_items', {
        params: {
          page,
          limit,
          ...prepareParams({ fromDateOrder, toDateOrder, fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet })
        }
      }));

    } else {
      setCount(0);
      setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, fromDateOrder, toDateOrder, /*fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet,*/ handleResponse, prepareParams, setCount, setList, dataReload]);

  return {
    list,
    count,
    inProgress,
    dataReloadInit
  };
};
