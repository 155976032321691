import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from 'react-router-dom';

import { Page } from 'components';
import { ProgressStepper } from './components/ProgressStepper';
import { CustomerInfo } from './components/CustomerInfo';
import { Items } from './components/Items';
import { Fulfillment } from './components/Fulfillment';
import { Payment } from './components/Payment';
import { axios_v2 } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 330,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  spinnerIcon: {
    color: '#fff',
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const currentStep = (step) => {
  return {
    0: <CustomerInfo />,
    1: <Items />,
    2: <Fulfillment />,
    3: <Payment />
  }[step];
};

export const CreateOrderDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);
  const {
    current_customer,
    step,
    addresses: {
      billing_address,
      delivery_options,
      shipping_address
    },
    products,
    comments,
    staff_notes,
    lease_provider,
    application_id,
    source,
    order_source,
    store_type,
    subtotal,
    shipping,
    grand_total
  } = useSelector(({ createOrder }) => createOrder);

  const checkStep = useCallback((step) => {
    switch (step) {
      case 1:
        return !!(billing_address?.first_name && billing_address?.last_name &&
          billing_address?.street_1 && billing_address?.city &&
          billing_address?.state && (billing_address?.state !== 'Choose a State') &&
          billing_address?.zip && billing_address?.phone && current_customer);
      case 2:
        return products.length > 0;
      case 3:
        return delivery_options && Object.values(delivery_options).every(opt => opt.selected_shipping_option !== undefined || !!opt.store_id);
      default:
        return false;
    }
  }, [billing_address, products, delivery_options, current_customer]);

  const checkCreate = useCallback((step) => {
    switch (step) {
      case 4:
        return !!(lease_provider && subtotal &&  grand_total && application_id);
      default:
        return false;
    }
  }, [lease_provider, subtotal, grand_total, application_id]);

  const handleNext = useCallback(() => {
    dispatch({
      type: 'CREATE_ORDER_SET_STEP',
      payload: step + 1
    });
  }, [dispatch, step]);

  const handleBack = useCallback(() => {
    dispatch({
      type: 'CREATE_ORDER_SET_STEP',
      payload: step - 1
    });
  }, [dispatch, step]);

  const handleCreate = useCallback(() => {
    const data = {
      current_customer,
      billing_address: { ...billing_address, email: current_customer?.email },
      shipping_address,
      delivery_options,
      products_data: products,
      lease_provider,
      application_id,
      source,
      order_source,
      store_type,
      staff_notes,
      comments,
      shipping,
      subtotal,
      grand_total
    };
    setLoading(true);
    axios_v2().post('create_order', data).then((response) => {
      if (response.data?.success) {
        setOpen(true);
        setSuccessOrder(true);
        setModalMessage(response.data.message);
        dispatch({
          type: 'SET_INITIAL'
        });
        setLoading(false);
      } else {
        setOpen(true);
        setModalMessage(response.data.message);
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
    });
  }, [dispatch, billing_address, comments, current_customer, delivery_options, lease_provider, application_id, source, store_type, order_source, products, shipping_address, staff_notes, shipping, subtotal, grand_total]);

  const handleViewOrders = () => {
    history.push('/orders');
  };

  const onCloseModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    const payload = {
      main_title: 'Create order',
      secondary_title: 'New order',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <Page
      className={classes.root}
      title="Order Management Details"
    >
      <Card
        className={classes.card}
      >
        <CardHeader
          subheader={<ProgressStepper activeStep={step} />}
        />
        <CardContent>
          {currentStep(step)}
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={step === 0 || loading}
            onClick={handleBack}
            variant="contained"
          >
            Back
          </Button>
          {step !== 3 && <Button
            color="primary"
            disabled={!checkStep(step + 1)}
            onClick={handleNext}
            variant="contained"
          >
            Next
          </Button>}
          {step === 3 && <Button
            color="primary"
            disabled={!checkCreate(step + 1) || loading}
            onClick={handleCreate}
            variant="contained"
          >
            {loading ? <CircularProgress
              className={classes.spinnerIcon}
              size={20}
            /> : <>Create</>}
          </Button>}
        </CardActions>
        <Modal open={open} onClose={onCloseModal}>
          <Card className={classes.modal}>
            <CardContent>{modalMessage}</CardContent>
            <CardActions className={classes.actions}>
              <Button
                onClick={onCloseModal}
                variant="contained"
              >
                Close
              </Button>
              {successOrder && <Button
                onClick={handleViewOrders}
                variant="contained"
              >
                View orders
              </Button>}
            </CardActions>
          </Card>
        </Modal>
      </Card>
    </Page>
  );
};
