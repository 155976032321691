import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
import moment from 'moment';
import { usePagination } from './usePagination';

export const useGetBestBuyCharges = () => {
  const { fromDate, toDate, queryText, status, setFromDate, setToDate } = useFiltersSearch();

  useEffect(function() {
    !fromDate && setFromDate(moment());
    !toDate && setToDate(moment());
  }, [fromDate, toDate, setFromDate, setToDate]);

  const prepareParams = useCallback(({fromDate, toDate, queryText, status}) => {
    const params = {};
    if (status) { params.status = status;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}

    if(queryText) { params.q = encodeURIComponent(queryText); }

    return params
  }, []);

  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((dataPromise) => {
    setCount(0);
    setList([]);
    setInProgress(true);

    dataPromise.then(response => {
      setInProgress(false);

      if (response?.data?.count) {
        setCount(response.data.count);

        if (response?.data?.data) {
          setList(response.data.data);
        }
      } else {
        setCount(0);
        setList([]);
      }
    }).catch(response => {
      setInProgress(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      toastHttpError(response);
    });
  }, [setCount, setList, setInProgress]);

  const {page, limit} = usePagination();

  useEffect(function() {

    if (fromDate && toDate) {

      handleResponse(axios().get('/reports/bestbuy_charges', {
        params: {
          page,
          limit,
          ...prepareParams({ fromDate, toDate, queryText, status })
        }
      }));

    } else {
      setCount(0);
      setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, fromDate, toDate, /*status,*/ queryText, handleResponse, prepareParams, setCount, setList, dataReload]);

  return {
    list,
    count,
    inProgress,
    dataReloadInit
  };
};
